@import "./variables";

.construction-elements {
  max-width: 1200px;
  margin: 0 auto;

  &__title {
    font-size: $font-size-lg;
    font-weight: bold;
    color: $text-color;
  }

  &__checklist {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: $spacing-xl;
    background-color: $secondary-color;
    padding: 10px;
    border-radius: $spacing-sm;
    box-shadow: 0 0 10px #7a7a7a;

    @media (min-width: $breakpoint-tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    &-item {
      display: flex;
      align-items: center;
      gap: $spacing-md;
      padding: $spacing-sm;
      background-color: $secondary-color;
      transition: background-color $transition-duration;

      &:hover {
        background-color: $hover-color;
      }

      &:last-child:nth-child(3n-1) {
        @media (min-width: $breakpoint-tablet) {
          grid-column: 2;
        }
      }

      &:last-child:nth-child(3n-2) {
        @media (min-width: $breakpoint-tablet) {
          grid-column: 2;
        }
      }
    }
  }

  &__add-new{
    input {
      padding: 8px;
      margin: 5px 5px 5px 0;
      font-size: 14px;
      border: none;
      border-radius: 5px;
    }

    Button {
      font-size: 12px;
      color: #fff;
      margin-bottom: 4px;
      background-color: green;
      &:hover {
        background-color: rgb(37, 167, 37);
      }
    }
  }

  &__label {
    font-size: $font-size-md;
    cursor: pointer;
  }

  &__tables {
    margin-top: $spacing-lg;

    &-container {
      margin-bottom: $spacing-xl;
      display: none;

      &.visible {
        display: block;
      }
    }

    &-title {
      font-size: $font-size-md;
      font-weight: bold;
      margin-bottom: $spacing-md;
      color: $text-color;
    }
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: $spacing-sm;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px #7a7a7a;

    &--quantities {
      width: 450px;
      box-shadow: 0 0 10px #7a7a7a;

      th,
      td {
        width: auto;
      }
    }

    &:not(.table--quantities) {
      th,
      td {
        &:first-child {
          width: 700px;
        }

        &:last-child {
          width: calc(100% - 700px);
          // min-width: 200px;
        }
      }
    }

    &__header {
      background-color: $secondary-color;

      th {
        padding: $spacing-sm;
        text-align: left;
        font-weight: bold;
        border-bottom: $border-width solid $border-color;
      }
    }

    &__body {
      td {
        padding: $spacing-xs $spacing-sm;
        border-bottom: $border-width solid $border-color;
        vertical-align: middle;
        display: table-cell;

        input {
          width: 87%;
          padding: $spacing-sm;
          border: $border-width solid $border-color;
          border-radius: $border-radius;
          font-size: $font-size-sm;
          transition: border-color $transition-duration;
          margin: auto 0;

          &:focus {
            outline: none;
            border-color: $primary-color;
          }
        }
      }
    }
  }

  &__text-content {
    background-color: white;
    padding: $spacing-md;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    margin-top: $spacing-md;
    line-height: 1.6;
    box-shadow: 0 0 10px #7a7a7a;

    strong {
      display: block;
      font-size: $font-size-md;
      margin-bottom: -40px;
    }

    br {
      content: "";
      display: block;
      margin: $spacing-sm 0;
    }
  }
}
