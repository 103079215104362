.devisProp {
  .title {
    position: relative;
    margin-top: 10vh;
    text-align: center;
  }
  .devis-payment {
    position: relative;
    display: flex;
    flex-direction: row;
    top: 20vh;
    margin-left: 5vw;
    div {
      margin-left: 2vw;
    }
  }
  .tabDev {
    margin-top: 100px;
    text-align: center;
    table.customTable {
      width: 330px;
      margin: auto;
      border-collapse: collapse;
    }

    table.customTable td,
    table.customTable th {
      border-width: 2px;
      background-color: #ffffff;
      border-style: solid;
      padding: 5px;
    }

    table.customTable {
      tbody tr:last-child {
        span {
          color: red;
        }
      }
    }
    table.customTable thead th:first-child {
      background-color: transparent;
      border-style: none;
    }
    table.customTable tbody {
      tr {
        td {
          &:nth-child(2),
          &:nth-child(3) {
            text-align: right;
          }
        }
      }
    }
  }
  .btn-group {
    display: flex;
    justify-content: center;
    button {
      margin: 10px 0 30px 10px;
      color: white;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
    .btn-create {
      background-color: green;
      &:hover {
        background-color: rgb(37, 167, 37);
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
    .spinner {
      width: 20px;
      margin-top: 10px;
    }
  }
  .genPDF {
    position: relative;
    left: 0;
    right: 0;
    top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    .numPages {
      text-align: right;
      font-size: 20px;

      button {
        margin-right: 0px;
      }
    }
    .closeIcon {
      background-color: transparent;
      &:hover {
        background-color: #e3e3e3;
      }
    }
  }
  .loadingPdf {
    svg {
      position: absolute;
      top: -50px;
      left: 0;
      right: 0;
      height: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 600px) {
  .devisProp {
    .d {
      position: absolute;
      top: 65px;
      left: -10px;
    }
  }
  .titl {
    position: absolute;
    top: -35px;
    left: 120px;
    font-size: x-large;
  }
  .customTable {
    position: absolute;
    top: 320px;
    left: 80px;
  }
  button {
    position: absolute;
    top: 470px;
    left: 150px;
    margin-top: 40px;
  }
  .docmenu {
    transform: translatex(-180%);
    position: absolute;
    top: -210px;
    left: 147px;
  }

  .headerco {
    position: absolute;
    top: 0px;
  }
}
