.reminder-detail {
  width: 600px;
  margin: 40px auto;
  .head-row {
    .MuiTableCell-root {
      text-align: center;
      font-weight: bold;
      font-size: 22px;
      color: #fff;
      background-color: #154bc0;
    }
  }
  .MuiTableCell-root {
    padding: 11px;
  }
  input {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-align: center;
    font-size: 17px;
    margin-left: -17px;
    border: none;
  }
  .client-response-radio {
    margin: 0 35px 0 17px;
  }
  .first-column-cell {
    width: 180px;
  }
  .create-btn {
    margin: 10px 180px;
    background-color: rgba(7, 105, 7, 0.863);
    &:hover {
      background-color: rgba(58, 128, 51, 0.863);
    }
  }
  .btn-group {
    display: flex;
    justify-content: flex-end;
    button {
      margin: 10px 0 30px 4px;
      color: white;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
    button:first-child {
      margin: 10px 0 30px 0px; 
    }
    .spinner {
      width: 20px;
      margin: 10px 0 0 -100px;
    }
  }
  @-moz-document url-prefix() {
    input {
      margin-left: -5px;
    }
    .client-response-radio {
      margin: 0 20px 0 5px;
    }
    .btn-group {
      button {
        margin: 10px 0 10px 5px;
      }
      button:first-child {
        margin: 10px 0 10px 0px; 
      }
    }
  }
  .last-row-cell {
    text-align: center;
    button {
      margin: 0 5px;
      color: white;
      background-color: rgba(7, 105, 7, 0.863);
      &:hover {
        background-color: rgba(58, 128, 51, 0.863);
      }
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
