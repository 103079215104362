.hist {
  position: absolute;
  width: 100%;
  h1 {
    margin-top: 120px;
    text-align: center;
    text-decoration: underline;
  }
  button {
    background-color: #ff7f50;
    color: white;
    &:hover {
      background-color: #f29f81;
    }
  }
  .ndossier {
    margin-top: 70px;
    margin-left: 5rem;
  }
  .popup-content {
    border: none;
  }
  .usersHist {
    width: 900px;
    margin: 20px auto 20px auto;
  }
  .generate-pdf-btn {
    margin-top: 10px;
  }
  .display-pdf-btn {
    margin: 10px 0 0 10px;
  }
  .usersHist__filter {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    input {
      margin: 0 8px 10px 8px;
      font-size: 15px;
    }
    Button {
      height: 25px;
      font-size: 13px;
    }
    .reset-btn {
      margin-left: 8px;
      background-color: #f5b039;
      &:hover {
        background-color: #f9d18c;
      }
    }
  }
  height: 100vh;
  overflow: auto;
  .docmenu {
    button {
      position: relative;
      margin-top: 6px;
      padding: 4px 0px 4px 0px;
      background-color: white;
      border-radius: 5px;
      margin-left: 50px;
      right: 10px;
    }
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 15vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      color: gray;
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
}
