.pgc-page4 {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;

    .custom-table {
      width: 851px;
      border-collapse: collapse;
      margin-bottom: 25px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;

        &:hover {
          background-color: #eee;
        }

        td:first-child {
          padding: 10px;
          height: 50px;
        }
        
        textarea {
          width: 95%;
          height: 100px;
          border: none;
          border-radius: 5px;
          resize: none;
        }
      }

      .custom-cell-title {
        border: 1px solid #01a467;
        text-align: center;
        color: #fff;
        background-color: #01a467;
      }
      .custom-cell-thead {
        border: 1px solid #c2c2c2fd;
        text-align: center;
        background-color: #c2c2c2fd;
      }
      .custom-cell-label {
        padding: 15px;
      }
      .add-risk {
        display: flex;
        justify-content: center;
        padding: 15px;
        background-color: transparent;
      }

      input[type="text"] {
        width: calc(100% - 20px);
        border: none;
        padding: 6px;
        border-radius: 5px;
      }

      select {
        border: none;
        padding: 6px;
        border-radius: 5px;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    button {
      margin: 0 5px;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
