.docmenu{
    position: relative;
    height: 40px;
    top: 50px;
    z-index: 10;
    button{
        position: relative;
        margin-top: 6px;
        padding: 4px 0px 4px 0px;
        background-color: white;
        border-radius: 5px;
        margin-left: 50px;
        right: 10px;
        span{
            font-size: 10px;
            color:black;
            .MuiSvgIcon-root{
                font-size: 20px;
            }
        }
        .barre{
            background-color: black;
            width: 1px;
            height: 15px;
            margin-left: 8px;
            margin-right: 4px;
        }
    }
    .others{
        float: right;
        position: relative;
    } 
    .print{
        float: right;
        position: relative;
    }
    .mail{
        float: right;
        position: relative;
    }
    .downloads{
        float: right;
        position: relative;
    }


    [class*="list"]{
        display: none;
        position: absolute;
        top: 40px;
        right: 10px;
        background-color: white;
        font-size: 12px;
        border-radius: 10px;
        padding: 0px;
        p{
            padding: 7px;
            &:hover{
                background-color: gainsboro;
                cursor: pointer;
            }
        }
    }
    [class*="is-menu-enabled"]{
        display:block;
    }
    .maillist{
        right: 10px;
    }
}