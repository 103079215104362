.data-page4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  .questions-table {
    width: 810px;
    border-collapse: initial;
    td {
      padding: 10px;
      text-align: center;
      border: 1px solid #333;
    }

    td.double-border {
      position: relative;
      background-color: #fff;
      color: #cc6600;
      font-weight: bold;
      font-style: italic;
    }

    td.double-border:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border: 1px solid #333;
    }

    td.col-1 {
      width: 70%;
    }

    tr {
      background-color: #fff;
    }

    tr:hover {
      background-color: #ddd;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    margin: 10px auto 30px;

    button {
      margin: 0 5px;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .spinner {
    margin-top: 0;
    position: inherit;
  }
}
