
  .About{
    color: white;
    padding: 8px 15px 8px 15px;
    border: none;
    font-size: 18px;
    background: transparent;
    text-decoration: none;
    margin-right: 3vw;
  }
  .About:hover{
    cursor: pointer;
  }
  .Login{
    color: white;
    padding: 8px 15px 8px 15px;
    background-color: #c75605;
    border-radius: 2px;
    border: none;
    font-size: 18px;
    text-decoration: none;
    margin-right: 3vw;
  }
  .Login:hover{
    background-color: rgb(141, 60, 6);
    cursor: pointer;
  }
  .Register{
    color: white;
    padding: 8px 15px 8px 15px;
    background-color: #c75605;
    border-radius: 2px;
    border: none;
    font-size: 18px;
    text-decoration: none;
    margin-right: 3vw;
  }
  .Register:hover{
    background-color: rgb(141, 60, 6);
    cursor: pointer;
  }
  
  
  .nav {
    width: 100%;
    background-color:transparent;
    position: relative;
    bottom: 4.1%;
  }
  
  .nav > .nav-btn {
    display: none;
    font-family: "Roboto-Regular", sans-serif;
  }
  
  .nav > .nav-links {
    display: inline;
    float: right;
    font-family: "Roboto-Regular", sans-serif;
  }
  
  .nav > #nav-check {
    display: none;
  }
  @media(max-height:500px){
    .About{
      font-size: 12px;
    }
    .Login{
      font-size: 12px;
    }
    .Register{
      font-size: 12px;
    }
  }
  @media (max-width:600px) {
    .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      margin-left: 86vw;
      top: 0px;
    }
    .nav > .nav-btn > label {
      display: inline-block;
      width: 20px;
      height: 50px;
      padding: 13px;
    }
    .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #eee;
    }
    .nav > .nav-links {
      position: absolute;
      display: block;
      width: 100%;
      background-color: #333;
      height: 0px;
      transition: all 0.3s ease-in;
      overflow-y: hidden;
      top: 50px;
      left: 0px;
      z-index: 10;
    }
    .nav > .nav-links > a {
      display: block;
      width: 100%;
    }
    .nav > #nav-check:not(:checked) ~ .nav-links {
      height: 0px;
    }
    .nav > #nav-check:checked ~ .nav-links {
      height: calc(100vh - 50px);
      overflow-y: auto;
    } 
    .About{
      background-color: #333;
    }
    .Login{
        background-color: #333;
        margin: 0px;
    }
    .Login:hover{
        background-color: #333;
    }
    .Register{
        background-color: #333;
        margin: 0px;
    }
    .Register:hover{
        background-color: #333;
    }
  }
@media (max-width:400px){
  .nav > .nav-btn > label {
    width: 30px;
  }
}