.agenda {
  .ndossier {
    position: absolute;
    display: flex;
    top: 140px;
    margin-left: 5rem;
    select {
      margin: 0 5px;
    }
  }
  .title {
    position: relative;
    margin-top: 70px;
    text-align: center;
  }
  .rbc-calendar {
    padding: 0 15px 15px 15px;

    .rbc-header {
      background-color: #f5f5f5;
      padding: 10px;
    }

    .custom-toolbar-label {
      padding-right: 5rem;
    }
  }
  
  .chrome-specific-style {
    width: 50px;
    margin-left: 15px;
  }
  @supports (-webkit-appearance: none) {
    .chrome-specific-style {
      width: 75px;
      margin-left: 15px;
    }
  }
}
