.rj-suivi {
  position: absolute;
  width: 100%;
  .rjmenu {
    position: relative;
    display: flex;
    top: 50px;
    margin-left: 5rem;
    .ndossier {
      top: 0;
    }
    .numero {
      margin-left: 5rem;
    }
    .advancement {
      margin-left: 5rem;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .data {
    position: relative;
    top: 55px;
    margin-left: 50px;
    .last-year-check {
      margin-top: 10px;
    }
    h3 {
      margin: 10px 0 5px 0;
    }
    .workforce-last-year {
      width: 560px;
      border-top: solid;
      padding-top: 10px;
    }
    .effectifs-table {
      .delete {
        margin: 0;
      }
      .BaseTable__table-main {
        outline: none;
      }
      .BaseTable__header-cell {
        border: 1px solid black;
        background-color: #c0bebb;
      }
      .BaseTable__row-cell {
        border: 1px solid black;
        font-size: 15px;
      }
      .BaseTable:not(.BaseTable--dynamic) .BaseTable__row-cell-text,
      .BaseTable .BaseTable__row--frozen .BaseTable__row-cell-text {
        white-space: normal;
      }
      .add-contact {
        margin: 20px 40px 20px 48px;
      }
    }
    button {
      margin: 20px 10px 20px 0;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .spinner {
    width: 20px;
    margin-top: 17px;
    position: absolute;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
