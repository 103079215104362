@keyframes move {
    100% {
        transform: translateY(-200%);
    }
}
@-webkit-keyframes move {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(300%);
    }
}
.pendingChantier {
    position: absolute;
    top: 298px;
    width: 36.5%;
    height: 234px;
    margin-bottom: px;
    text-align: center;
    font-family: Calibri;
    font-size: 14px;
    border-bottom: solid 1px black;

    > .titrechantier{
        display: block;
        position: relative;
        background-color: #ff7f50;
        color: white;
        border-bottom: solid 1px black;
        width: auto;
        bottom:0;
        top:0;
        left:0;
        right:0;
        padding: 10px;
    }

    > .chantierHeader{
        display: block;
        position: relative;
        font-size: 12px;
        width: auto;
        bottom:0;
        top:0;
        left:0;
        right:0;
        padding: 5px;
        background-color: white ;
        border: solid 1px black;
        border-top: none;
        border-right: none;
        bottom: 20px;
        
        > span{
            height: 100%;
            padding: 10px 2.5vw 10px 2.5vw;
        }
    }

    div{
        font-size: 14px;
        max-height: 190px;
        overflow: hidden;
        scrollbar-width: thin;
        scrollbar-color: rgb(209, 209, 209) transparent;
        scrollbar-track-color: rgb(209, 209, 209);
        .dossiers{
            li{ 
                display: block;          
               /* position: relative;
               animation-name: move;
               animation-duration: 4s;
               animation-iteration-count: infinite;
               animation-timing-function: linear;
               -webkit-animation-name: move;
               -webkit-animation-duration: 4s;
               -webkit-animation-iteration-count: infinite;
               -webkit-animation-timing-function: linear;*/
            }
           > li > .iddossier{
            padding: 10px 3.5vw 10px 0vw;
            margin-left: -1.5vw;
           }
           > li > .item{
            padding: 10px 2.2vw 10px 3vw;
           }
           > li > .datedeb{
            padding: 10px 1.8vw 10px 3vw;
           }
           > li > .datefin{
            padding: 10px 0vw 10px 3vw;
           }
       }
    }
    div:hover{
        > li{
            animation-play-state: paused;
        }
    }
    /*div::-webkit-scrollbar {
            width: 7px;
        }
          
    div::-webkit-scrollbar-track {
            background: transparent;
          }
          
    div::-webkit-scrollbar-thumb {
            background-color: rgb(209, 209, 209);
            border-radius: 20px;
        }*/
    > span{
        display: none;
        position: relative;
        font-size: 30px;
        top: 0px;
    }
}
@media(max-width:1800px){
        .pendingChantier  .chantierHeader {
        span{
            padding: 10px 1.5vw 10px 1.5vw;
        }
    }
        .pendingChantier div .dossiers > li > .iddossier{
            padding: 10px 5vw 10px 0vw;
            margin-left: -1.5vw;
        }
        .pendingChantier div .dossiers > li > .item{
            padding: 10px 2.5vw 10px 0vw;
        }
        .pendingChantier div .dossiers > li > .datedeb{
            padding: 10px 0vw 10px 1vw;
        }
        .pendingChantier div .dossiers > li > .datefin{
            padding: 10px 0vw 10px 3vw;
        }
}
@media(max-width:1400px){
    .pendingChantier{
        width: 50%;
    }
}
@media(max-width:700px){
    .pendingChantier{
        top: 773px;
        width: 99.7%;
        border: solid 1px black;
    }
    .pendingChantier  .chantierHeader {
        span{
            padding: 10px 5vw 10px 5vw;
        }
    }
        .pendingChantier div .dossiers > li > .iddossier{
            padding: 10px 15vw 10px 0vw;
            margin-left: -4vw;
        }
        .pendingChantier div .dossiers > li > .item{
            padding: 10px 11vw 10px 0vw;
        }
        .pendingChantier div .dossiers > li > .datedeb{
            padding: 10px 5vw 10px 0vw;
        }
        .pendingChantier div .dossiers > li > .datefin{
            padding: 10px 0vw 10px 5vw;
        }
}
@media(max-width:450px){
    .pendingChantier  .chantierHeader {
        span{
            padding: 10px 3.5vw 10px 3.5vw;
        }
    }
    .pendingChantier div .dossiers > li > .iddossier{
        padding: 10px 11vw 10px 0vw;
        margin-left: -4vw;
    }
    .pendingChantier div .dossiers > li > .item{
        padding: 10px 9vw 10px 0vw;
    }
    .pendingChantier div .dossiers > li > .datedeb{
        padding: 10px 1vw 10px 0vw;
    }
    .pendingChantier div .dossiers > li > .datefin{
        padding: 10px 0vw 10px 5vw;
    }
}
