.pgc-menu{
  position: relative;
  display: flex;
  margin-left: 5rem;
  .ndossier {
    top: 0;
  }
  .indice{
    margin-left: 2rem;
  }
}