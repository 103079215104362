@use "./CompTable.scss" as *;
@use "./AffaireTable.scss" as *;

.page7 {
  position: absolute;
  width: 100%;
  // .docmenu{
  //     top: 8.7rem;
  //     right: 5rem;
  // }
  .spinner {
    width: 20px;
    margin-top: 17px;
    position: absolute;
  }
  .vicmenu {
    position: relative;
    display: flex;
    top: 50px;
    margin-left: 5rem;
    .ndossier {
      top: 0;
    }
    .lot {
      margin-left: 5rem;
    }
    .entrepriselist {
      margin-left: 5rem;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .data {
    position: relative;
    top: 80px;
    margin: auto auto 110px 50px;
    .infoData {
      div {
        margin-top: 20px;
      }
    }
    .titleConv {
      border: 1px solid black;
      text-align: center;
      width: fit-content;
      padding: 20px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
      h2 {
        color: red;
      }
    }
    button {
      margin-top: 20px;
      margin-right: 40px;
      background-color: rgba(255, 127, 80, 0.73);
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .testTable {
    display: flex;
    margin-bottom: 20px;
    .BaseTable__table-main {
      outline: none;
    }
    .BaseTable__body {
      border: 1px solid black;
    }
    .BaseTable__header-cell {
      border: 1px solid black;
      background-color: rgb(251, 228, 213);
    }
    .BaseTable__row-cell {
      border: 1px solid black;
      font-size: 15px;
    }
    .BaseTable:not(.BaseTable--dynamic) .BaseTable__row-cell-text,
    .BaseTable .BaseTable__row--frozen .BaseTable__row-cell-text {
      white-space: normal;
    }
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
  }
}
