.contract-amendment {
  .ndossier {
    position: absolute;
    display: flex;
    top: 190px;
    margin-left: 5rem;
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .data {
    .contract-amendment-infos {
      width: 680px;
      margin: 60px auto 10px auto;
      .head-row {
        .MuiTableCell-root {
          text-align: center;
          font-size: 22px;
          color: #fff;
          background-color: #154bc0;
        }
      }
    }
    .MuiTableCell-root:first-child {
      width: 195px;
    }
    .contract-amendment-table {
      width: 680px;
      margin: 20px auto;
      .btn-group {
        display: flex;
        justify-content: flex-end;
        button {
          margin: 10px 0 30px 10px;
          color: white;
          background-color: #ff7f50;
          &:hover {
            background-color: #ff9b61;
          }
          &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        }
        .spinner {
          width: 20px;
          margin-top: 10px;
        }
        button:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
        .btn-create {
          background-color: green;
          &:hover {
            background-color: rgb(37, 167, 37);
          }
        }
      }
    }
    .delai {
      text-align: right;
      span {
        color: red;
      }
    }
    .genPDF {
      position: absolute;
      right: 5vw;
      top: 25vh;
      .numPages {
        font-size: 20px;
        text-align: right;
      }
      .closeIcon {
        float: right;
        position: absolute;
        z-index: 10;
        right: 0px;
      }
      button {
        background-color: transparent;
      }
      .loadingPdf svg {
        height: 200px;
        position: absolute;
        top: 20vh;
        margin: 0 -130px;
      }
    }
  }
}
