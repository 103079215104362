.data-page6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 65px;

  .observation-table {
    border-collapse: collapse;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .observation-table th,
  .observation-table td {
    height: 50px;
    text-align: center;
    border: 1px solid #ddd;
  }

  .observation-table th {
    background-color: #ffba8c;
    font-weight: 600;
    color: #333;
  }

  .observation-table td {
    background-color: #f2f2f2;
  }

  .add-observation {
    width: 115px;
    display: flex;
    justify-content: center;
    padding: 5px;
    background-color: transparent;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin: 10px auto 30px;

    button {
      margin: 0 5px;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .spinner {
    margin-top: 0;
    position: inherit;
  }
}
