.devisValid {
  .ndossier {
    position: absolute;
    display: flex;
    top: 190px;
    margin-left: 5vw;
    div {
      margin-left: 2vw;
    }
  }
  .title {
    position: relative;
    margin-top: 10vh;
    text-align: center;
  }
  .genPDF {
    overflow: hidden;
    width: fit-content;
    margin-top: 80px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    .closeIcon {
      position: relative;
      z-index: 10;
      float: right;
      top: 50px;
    }
  }
  .loadingPdf {
    margin: 20vh;
    svg {
      position: relative;
      z-index: 10;
      left: 0vw;
      height: 200px;
    }
  }

  .buttons {
    display: flex;
    position: relative;
    top: 110px;
    justify-content: center;
    button {
      margin-right: 20px;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
    button:nth-child(2) {
      background-color: #008000;
      &:hover {
        background-color: #25a725;
      }
    }
    button:last-child {
      background: #ff5070f3;
      &:hover {
        background-color: #f76c6c;
      }
    }
    .renvoybout {
      position: absolute;
      top: -20px;
      left: 970px;
    }
    .modifbout {
      position: absolute;
      top: -20px;
      left: 600px;
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .numPages {
    font-size: 20px;
    text-align: right;
  }
}
@media (max-width: 600px) {
  .devisValid {
    .buttons {
      .affichbout {
        position: absolute;
        top: -400px;
        left: -150px;
      }
      .validation {
        position: absolute;
        top: -280px;
        left: -275px;
      }
      .renvoybout {
        position: relative;
        top: -200px;
        left: -135px;
      }
      .modifbout {
        position: absolute;
        top: -60px;
        left: -150px;
      }
    }
  }
  .docmenu {
    transform: translatex(-180%);
    position: absolute;
    top: -210px;
    left: 147px;
  }
  .d {
    position: absolute;
    top: 25px;
    left: 0px;
    font-size: 15px;
  }
  .title {
    position: absolute;
    top: 0px;
    left: 10px;
  }
}

@media (min-width: 810px) and (max-width: 1080px) {
  .devisValid {
    .d {
      position: absolute;
      top: -28px;
      left: -10px;
      font-size: 12px;
    }
    .buttons {
      .affichbout {
        position: absolute;
        top: -22px;
        left: -80px;
      }
      .validation {
        position: absolute;
        top: -22px;
        left: 90px;
      }
      .modifbout {
        position: absolute;
        top: -22px;
        left: 400px;
      }
      .renvoybout {
        position: absolute;
        top: 42px;
        left: -80px;
      }
    }
  }
}
