@keyframes fade{
    0% {opacity: 1;}
    50%   {opacity:0; }
  100% { opacity:1; }
}
@keyframes fadein{
    0% {opacity: 0;}
  100% { opacity:1; }
}
.process{
    position: absolute;
    animation-duration: 5s;
    animation-name: fade;
    animation-iteration-count: infinite;
    transition: none;
    margin-top: 520px;
    left: 13vw;
}
.process:hover{
    animation-duration: 2s;
    animation-name: fadein;
    animation-iteration-count: 1;
    transition:none;
    cursor: pointer;
}
.logoprocess{
    border-radius: 10%;
    height: 130px;
    width: 230px;
}
.flechehaut1{
    position: inherit;
    transform: rotate(180deg);
    height: 45px;
    bottom: 150px;
    left: 97px;
}
.process label{
    position: inherit;
    font-size: 20px;
    left: 50px;
    bottom: 220px;
    font-family: Calibri;
}
@media (max-width:1000px){
    .process{
        margin-top: 480px;
        left: 10vw;
    }
    .logoprocess{
        width: 150px;
    }
    .flechehaut1{
        width: 30px;
        left: 60px;
    }
    .process > label{
        text-align: center;
        font-size: 15px;
        left: 20px;
    }
}
@media (max-width:700px){
    .process{
        margin-top: 430px;
        left: 6vw;
    }
    .logoprocess{
        width: 120px;
        height: 100px;
    }
    .flechehaut1{
        display: none;
    }
    .process > label {
        font-size: 12px;
        left: 15px;
        bottom: 0px;
        top: 110px;
    }
}
@media only screen and (max-width:400px) and (max-height: 800px){
    .process{
        margin-top: 540px;
        left: 20px;
    }
    .process > label{
        left: 12px;
    }
}
@media (max-width:500px){
    .process{
        margin-top: 540px;
        left: 20px;
    } 
}