.send-mail {
  // position: absolute;
  .ndossier {
    position: absolute;
    display: flex;
    top: 150px;
    margin-left: 5rem;
    label {
      margin: 0 5px;
    }
  }
  .title {
    width: 100%;
    margin: 100px auto 0 auto;
    text-align: center;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 10vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  //   .mail-params {
  // position: absolute;
  // top: 150px;
  //   }
  //   .mail-submit {
  // position: absolute;
  // top: 50px;
  // div {
  //   margin-top: 10px;
  // }
  // .mailBody > label {
  //   position: absolute;
  //   left: 0px;
  // }
  // textarea {
  //   width: 50vw;
  //   height: 400px;
  //   margin-left: 48px;
  //   white-space: pre-line;
  // }
  // button {
  //   margin-left: 20px;
  // }
  //   }
  //   .ndos {
  //     position: relative;
  //     display: flex;
  //     top: 25px;
  //     left: 500px;
  //     margin-left: 5rem;
  //   }
}
