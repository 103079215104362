.table-container {
  width: 1005px;

  .sheet-other {
    margin-top: 20px;
    padding-bottom: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px #7a7a7a;
    border-radius: 8px;

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 10px;
        text-align: center;
      }

      td {
        width: 50%;
      }

      th {
        font-size: larger;
        padding: 20px;
        color: #fff;
        background-color: #01308d;
      }

      textarea {
        margin-top: -5px;
        width: 95%;
        height: 100px;
        padding: 5px;
        resize: none;
      }
      .img-item {
        position: relative;
        // margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item-delete {
          color: "white";
          width: 32px;
          height: 32px;
          margin: 4px 9px;
          background-color: rgba(190, 187, 187, 0.541);
        }

        .item-delete:hover {
          color: "white";
          width: 32px;
          height: 32px;
          background-color: #ff7f50;
        }

        p {
          margin: 10px 0 -8px;
        }
      }
    }
  }
  .sheet-3-table {
    margin-top: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px #7a7a7a;
    border-radius: 8px;

    .sheet-3-table-1 {
      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding-left: 20px;
          text-align: center;
        }

        td {
          width: 50%;
        }

        th {
          font-size: larger;
          padding: 20px;
          color: #fff;
          background-color: #01308d;
        }
      }
    }

    .sheet-3-table-2 {
      padding-bottom: 20px;
      display: flex;
      justify-content: center;

      table {
        width: 95%;
        border-collapse: collapse;

        thead {
          tr {
            th {
              border: 1px solid #000;
              padding: 8px;
              background-color: #f2f2f2;
              text-align: center;
            }
          }
        }

        tbody {
          tr {
            td {
              text-align: center;
              border: 1px solid #000;
              padding: 8px;
              width: 25%;
            }

            td:first-child {
              text-align: left;
              width: 27%;
            }
          }

          tr {
            background-color: #ffc296;
          }
        }
      }
    }

    .sheet-3-table-3 {
      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding-left: 20px;
          text-align: center;
        }

        td {
          width: 50%;
        }
      }
      &-2 {
        display: flex;
        justify-content: center;

        table {
          margin-bottom: 20px;
          width: 95%;
          border-collapse: collapse;

          thead {
            tr {
              th {
                border: 1px solid #000;
                padding: 8px;
                background-color: #f2f2f2;
                text-align: center;
              }
            }
          }

          tbody {
            tr {
              td {
                text-align: center;
                border: 1px solid #000;
                padding: 8px;
                width: 26%;
              }

              td:first-child {
                text-align: left;
                width: 22%;
              }
            }
          }
        }
      }
      &-3 {
        padding-bottom: 30px;
        table {
          tr {
            td {
              text-align: left;
              padding-bottom: 50px;
            }
          }
        }
      }
    }
  }
  .sheet-5-table {
    margin-top: 20px;
    padding-bottom: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px #7a7a7a;
    border-radius: 8px;

    &-1 {
      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding-left: 20px;
          text-align: center;
        }

        td {
          width: 50%;
        }

        th {
          font-size: larger;
          padding: 20px;
          color: #fff;
          background-color: #01308d;
        }
      }
    }

    &-2 {
      padding: 20px 0;
      display: flex;
      justify-content: center;

      table {
        width: 95%;
        border-collapse: collapse;

        thead {
          tr {
            th {
              border: 1px solid #000;
              padding: 8px;
              background-color: #c1c1c1;
              text-align: center;
            }
          }
        }

        tbody {
          tr {
            td {
              text-align: center;
              border: 1px solid #000;
              padding: 8px;
              width: 20%;

              &:first-child {
                background-color: #e3e3e3;
              }
            }
          }
        }
      }
    }
  }
  .sheet-8-table {
    margin-top: 20px;
    padding-bottom: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px #7a7a7a;
    border-radius: 8px;

    &-1 {
      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding-left: 20px;
          text-align: center;
        }

        td {
          width: 50%;
        }

        th {
          font-size: larger;
          padding: 20px;
          color: #fff;
          background-color: #01308d;
        }
      }
    }
    .img-container {
      margin: 20px 0;
      display: flex;
      justify-content: space-evenly;
      img {
        width: 18%;
        height: 200px;
      }
    }
    &-details {
      margin-left: 20px;

      span {
        margin-left: 25px;
      }
    }
  }
  .sheet-11-table {
    margin-top: 20px;
    padding-bottom: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px #7a7a7a;
    border-radius: 8px;

    &-1 {
      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding-left: 20px;
          text-align: center;
        }

        td {
          width: 50%;
        }

        th {
          font-size: larger;
          padding: 20px;
          color: #fff;
          background-color: #01308d;
        }
      }
    }
    &-details {
      margin: 10px 20px;

      span {
        margin-left: 25px;
      }
    }
  }
}
