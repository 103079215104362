.diuo-page8 {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .custom-table {
      width: 851px;
      border-collapse: collapse;
      margin-top: 25px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;
        &:hover {
          background-color: #eee;
        }

        textarea {
          width: 99%;
          height: 100px;
          padding: 5px;
          border: none;
          border-radius: 5px;
          resize: none;
        }
      }

      .custom-cell-head-title {
        border: 1px solid #01308d;
        text-align: center;
        color: #fff;
        background-color: #01308d;
        padding: 20px;
      }

      .custom-cell-label {
        padding: 15px;
      }

      .custom-cell-title {
        border: 1px solid #c2c2c2fd;
        text-align: center;
        background-color: #c2c2c2fd;
      }

      .custom-cell-text {
        padding: 15px;
      }

      .custom-cell-value {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 70px;
        width: 250px;
      }
    }
    input[type="checkbox"] {
      transform: scale(1.25);
      -webkit-transform: scale(1.25);
      -moz-transform: scale(1.25);
      -ms-transform: scale(1.25);
      -o-transform: scale(1.25);
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    button {
      margin: 0 5px;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
