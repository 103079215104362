.diuo-page7 {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .checkbox-grid {
      width: 985px;
      display: flex;
      flex-wrap: wrap;
      background-color: #f5f5f5;
      box-shadow: 0 0 10px #7a7a7a;
      padding: 10px;
      border-radius: 8px;
    }
    
    .checkbox-item-left {
      width: 450px;
      margin: 5px;
      box-sizing: border-box;
    }
    
    .checkbox-item-right {
      margin: 5px;
      box-sizing: border-box;
    }
    
    .checkbox-item-left label,
    .checkbox-item-right label {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    button {
      margin: 0 5px;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
