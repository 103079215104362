.fac-amendment {
  padding-bottom: 40px;
  .ndossier {
    position: absolute;
    display: flex;
    top: 190px;
    margin-left: 5rem;
    label {
      margin: 0 5px;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .data {
    .btns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .btn {
        margin: 15px 20px 0 0;
        background-color: rgba(255, 127, 80, 0.73);
        &:hover {
          background-color: rgb(253, 181, 142);
        }
      }
    }
    .fac-amendment-infos {
      width: 680px;
      margin: 40px auto;

      .head-row {
        .MuiTableCell-root {
          text-align: center;
          font-size: 22px;
          color: #fff;
          background-color: #154bc0;
        }
      }
      .MuiTableCell-root:first-child {
        width: 195px;
      }
      .create-btn {
        display: block;
        margin: 10px auto;
        background-color: rgba(7, 105, 7, 0.863);
        &:hover {
          background-color: rgba(58, 128, 51, 0.863);
        }
      }
      button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
    .fac-amendment-detail {
      width: 680px;
      margin: 40px auto;

      .ReactTable {
        .rt-thead .rt-th {
          font-weight: bold;
        }

        .rt-thead.-header {
          pointer-events: none;
          .rt-resizer {
            pointer-events: visible;
          }
        }

        .rt-head .rt-tr {
          font-weight: bold;
        }

        .rt-table {
          background: white;
          border: 2px solid black;

          .rt-tbody {
            background-color: white;

            .titleDevisCon2,
            .titleDevisRea {
              font-size: 15px;
              font-weight: bold;
            }

            .rt-tr-group {
              &:first-child,
              &:nth-child(5),
              &:nth-child(9),
              &:nth-child(13) {
                border-top: 1px solid black;
                border-bottom: 1px solid black;
              }

              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(6),
              &:nth-child(7),
              &:nth-child(8),
              &:nth-child(10),
              &:nth-child(11),
              &:nth-child(12) {
                .rt-td:first-child {
                  border-right: 1px solid black;
                }
              }

              &:nth-child(5),
              &:nth-child(9),
              &:nth-child(13) {
                border-top: 1px solid black;
                border-bottom: 1px solid black;
              }

              .rt-tr-group {
                border: none;
              }
            }
          }
        }
      }

      .pagination-bottom {
        display: none;
      }

      .tva {
        display: flex;
        margin: 10px;

        .MuiSlider-root {
          position: relative;
          color: tomato;
          top: 0;
          width: 15rem;
        }
      }

      .btn-group {
        display: flex;
        justify-content: flex-end;
        button {
          margin: 0 0 30px 10px;
          color: white;
          background-color: #ff7f50;
          &:hover {
            background-color: #ff9b61;
          }
          &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        }
        .spinner {
          width: 20px;
        }
        button:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
        .btn-create {
          background-color: green;
          &:hover {
            background-color: rgb(37, 167, 37);
          }
        }
      }
    }
  }

  .status {
    display: flex;
    justify-content: center;
    .green-light {
      background-color: green;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 10px;
      border: #000000 solid 2px;
    }
    .yellow-light {
      background-color: rgb(253, 152, 0);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 10px;
      border: #000000 solid 2px;
    }
    .red-light {
      background-color: rgb(255, 2, 2);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 10px;
      border: #000000 solid 2px;
    }
    span {
      font-size: 20px;
    }
    div:nth-child(3) + span {
      margin-top: -3px;
    }
    div:nth-child(5) + span {
      margin-top: -3px;
    }
    .hidden {
      display: none;
    }
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
}
