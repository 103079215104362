@import '../Dashboard/FactureCo.scss';
@import '../Dashboard/RDV.scss';
@import '../Dashboard/Opportunity.scss';
@import '../Dashboard/GestDoc.scss';
@import '../Dashboard/Activities.scss';
@import '../Dashboard/ChantierEnCours.scss';
@import '../Dashboard/GraphChantier.scss';
@import '../Dashboard/Calendar.scss';
@import '../Dashboard/OtherGraphs.scss';
@import '../Dashboard/NotifList.scss';
html, body {
    padding: 0;
    height: 100%;
    max-width: 100%; 
    min-width: 400px;  
    background: linear-gradient(#f7ac2c, 50%,#e0dddd);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
} 
.dashboard{
    position: absolute;
    width: 100%;
}
.docs{
    position: absolute;
    font-family: Calibri;
    right: 0px;
    top: 50px;
    width: 33.3%;
    font-size: 14px;
    > .titredocs{
        display: block;
        position: relative;
        color: white;
        text-align: center;
        background-color: #ff7f50;
        bottom: 20px;
        border: solid 1px black;
        width: auto;
        bottom:0;
        top:0;
        left:0;
        right:0;
        padding: 11px 10px 10px 10px;
    }
}
@media(max-width:1700px){
    .docs .facture label{
        font-size: 24px;
    }
    .docs .facture p{
        top: 30px;
        font-size: 16px;
        span{
            top: -4px;
            font-size: 24px;
        }
    }
    .docs .gestdoc label{
        font-size: 24px;
    }
    .docs .gestdoc p{
        top: -15px;
        font-size: 16px;
        span{
            top: -4px;
            font-size: 24px;
        }
    }
    .docs .activities label{
        font-size: 24px;
    }
    .docs .activities p{
        top: -0px;
        font-size: 16px;
        span{
            top: -4px;
            font-size: 24px;
        }
    }
    .docs .opportunity label{
        font-size: 24px;
    }
    .docs .opportunity p{
        top: 0px;
        font-size: 16px;
        span{
            top: -4px;
            font-size: 24px;
        }
    }
}
@media(max-width:1400px){
    .docs{
        top: 968px;
        left: 0px;
        width: 100%;
    }
}
@media(max-width:700px){
    .docs{
        top: 1450px;
    }
}
