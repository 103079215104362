.rjEmploymentLaw {
  position: absolute;
  width: 100%;
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  h2 {
    text-align: center;
  }
  .ct-categories {
    text-align: center;
  }
  .buttons {
    text-align: end;
  }
  .cat-button {
    margin-bottom: 10px;
    margin-left: 10px;
    background-color: #ff7f50;
    &:hover {
      background-color: #ff9b61;
    }
  }
  .ct-button {
    margin-top: 20px;
    margin-left: 10px;
    background-color: #ff7f50;
    &:hover {
      background-color: #ff9b61;
    }
  }
  .display-employment-law {
    margin: 30px;
    @media screen and (min-width: 1100px) {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: calc((100% - 1100px) / 2);
      margin-right: calc((100% - 1100px) / 2);
    }
    .BaseTable__header-cell {
      border: 1px solid black;
      background-color: #ccc;
    }
    .BaseTable__row-cell {
      border: 1px solid black;
      font-size: 15px;
    }
    .BaseTable__row {
      .BaseTable__row-cell:nth-child(4) {
        display: flex;
        justify-content: flex-start;
        padding-left: 40px;
      }
    }
    .read-employment-law {
      margin: 20px 40px 20px 48px;
    }
    .edit-employment-law {
      margin: 20px 40px 20px 48px;
    }
    .del-employment-law {
      margin: 20px 40px 20px 48px;
    }
  }
  .display-categories {
    margin: 30px;
    @media screen and (min-width: 630px) {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: calc((100% - 630px) / 2);
      margin-right: calc((100% - 630px) / 2);
    }
    .BaseTable__header-cell {
      border: 1px solid black;
      background-color: #ccc;
    }
    .BaseTable__row-cell {
      border: 1px solid black;
      font-size: 15px;
    }
    .BaseTable__row {
      .BaseTable__row-cell:nth-child(4) {
        display: flex;
        justify-content: flex-start;
        padding-left: 40px;
      }
    }
    .read-employment-law {
      margin: 20px 40px 20px 48px;
    }
    .edit-employment-law {
      margin: 20px 40px 20px 48px;
    }
    .del-employment-law {
      margin: 20px 0px 20px -23px;
    }
  }
  .spinner {
    margin-left: calc((100% - 40px) / 2);
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
