.graphs{
    position: absolute;
    font-family: Calibri;
    top: 50px;
    right: 33.15%;
    width: 30.3%;
    height: 481px;
    text-align: center;
    border: solid 1px black;
    border-right: none;
    font-size: 14px;
    > .titregraphs{
        display: block;
        position: relative;
        color: white;
        text-align: center;
        background-color: #ff7f50;
        border-bottom: solid 1px black;
        width: auto;
        bottom:0;
        top:0;
        left:0;
        right:0;
        padding: 10px;
    }
}
@media(max-width:1400px){
    .graphs{
        left: 50%;
        width: 50%;
        div{
            margin-left: 5vw;
            width: 40vw;
        }
    }
}
@media(max-width:1000px){
    .graphs{
        div{
            margin-top: 10%;
            width: 89%;
        }
    }
}
@media(max-width:800px){
    .graphs{
        div{
            margin-top: 25%;
        }
    }
}
@media(max-width:700px){
    .graphs{
        left: 0px;
        width: 99.7%;
        top: 291px;
        border: black solid 1px;
        div{
            margin-top: 20px;
        }
    }
}
@media(max-width:400px){
    .graphs{
        div{
            margin-top:80px
        }
    }
}