@use "./RisqueTable.scss" as *;
.rjEval {
  position: absolute;
  width: 100%;
  .rjmenu {
    position: relative;
    display: flex;
    top: 50px;
    margin-left: 5rem;
    .ndossier {
      top: 0;
    }
    .numero {
      margin-left: 5rem;
    }
    .advancement {
      margin-left: 5rem;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .consignes {
    position: relative;
    left: 70px;
    top: 100px;
    width: 90%;
    .blocs {
      display: flex;
      align-items: center;
      justify-content: center;
      .bloc1 {
        height: 50px;
        width: 50px;
        background-color: red;
        input {
          margin-top: 17.5px;
          margin-left: 12px;
        }
      }
      .bloc2 {
        height: 50px;
        width: 50px;
        background-color: purple;
        input {
          margin-top: 17.5px;
          margin-left: 12px;
        }
      }
      .bloc3 {
        height: 50px;
        width: 50px;
        background-color: yellow;
        input {
          margin-top: 17.5px;
          margin-left: 12px;
        }
      }
      .bloc4 {
        height: 50px;
        width: 50px;
        background-color: green;
        input {
          margin-top: 17.5px;
          margin-left: 12px;
        }
      }
    }
    
    input[type="date"] {
      padding: 5px;
      border: none;
      border-radius: 5px;
    }
  }
  .displayFloors {
    display: flex;
  }
  .rjButton {
    margin: 20px 10px 20px 0;
    background-color: #ff7f50;

    &:hover {
      background-color: #ff9b61;
    }
  }

  .spinner {
    width: 20px;
    margin-top: 17px;
    position: absolute;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
