.data-page7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  .title-conv {
    width: 528px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px #7a7a7a;
    h3 {
      color: red;
    }
  }

  .custom-table {
    width: 558px;
    border-collapse: collapse;
    margin-top: 30px;
    box-shadow: 0 0 10px rgb(122, 122, 122);

    .works {
      text-align: center;
      background-color: #d5d5d5;
      td {
        padding: 0;
      }
    }

    .custom-cell {
      padding: 15px;
      border: 1px solid #ddd;
      text-align: center;

      .custom-cell-check {
        width: 50px;
        border: 1px solid #ddd;
        padding: 10px;
      }

      .custom-cell-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
      }
    }
    tr {
      background-color: #f5f5f5;
      td {
        position: relative;
      }
    }
  }

  .custom-table-companies {
    width: 558px;
    border-collapse: collapse;
    box-shadow: 0 0 10px rgb(122, 122, 122);
    tr {
      background-color: #f5f5f5;
      padding: 15px;
      border: 1px solid #ddd;
      td {
        width: 100%;
        padding: 10px;
      }
      td:last-child {
        .date {
          width: 95%;
        }
        text-align: center;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin: 30px auto;

    button {
      margin: 0 5px;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .spinner {
    margin-top: 0;
    position: inherit;
  }
}
