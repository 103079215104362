.contratech {
  #langAmendment {
    margin-left: 5px;
  }
  .ndossier {
    position: absolute;
    display: flex;
    top: 190px;
    margin-left: 5rem;
  }
  .title {
    position: relative;
    margin-top: 80px;
    text-align: center;
  }
  .data {
    .table-data {
      width: 500px;
      margin: "40px auto 10px auto";
    }
    .head-row {
      .MuiTableCell-root {
        text-align: center;
        // font-weight: bold;
        font-size: 22px;
        color: #fff;
        background-color: #777777;
      }
    }
    input {
      width: 100%;
    }
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  .data {
    .react-autosuggest__input {
      width: 100%;
    }
  }
  .echtable {
    width: 680px;
    margin: 20px auto;
    .btn-group {
      display: flex;
      justify-content: flex-end;
      button {
        margin: 10px 0 30px 10px;
        color: white;
        background-color: #ff7f50;
        &:hover {
          background-color: #ff9b61;
        }
        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }
      .spinner {
        width: 20px;
        margin-top: 10px;
      }
      button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
      .btn-create{
        background-color: green;
        &:hover {
          background-color: rgb(37, 167, 37);
        }
      }
      .btn-dell{
        background-color: rgb(255, 1, 1);
        &:hover {
          background-color: rgb(255, 71, 39);
        }
      }
    }
  }
  .delai {
    text-align: right;
    span {
      color: red;
    }
  }
}
