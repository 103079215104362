.diuo-page2 {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .custom-table {
      width: 1100px;
      border-collapse: collapse;
      margin-top: 15px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      // Style de base pour toutes les cellules du tableau
      td {
        border: 1px solid #ddd;
        background-color: #f5f5f5;
        padding: 15px;

        input, select {
          width: 95%;
          text-align: center;
          border: none;
          padding: 6px;
          border-radius: 5px;
        }
        select {
          width: 100%;
        }
        textarea {
          border: none;
          resize: none;
          border-radius: 5px;
        }
      }

      // Style pour le titre
      .custom-cell-title {
        td {
          border: 1px solid #01308d;
          text-align: center;
          color: #fff;
          background-color: #01308d;
        }
      }

      td {
        border: 1px solid #ddd;
        background-color: #f5f5f5; // Couleur de fond fixe
        padding: 15px;
      }
    
      tr:not(.custom-cell-title):not(.add-maintenance-recommendations) {
        &:hover td,                     
        &:hover td[rowspan],            
        &:has(~ tr[data-group-id="${'${attr(data-group-id)}'}"]:hover) td,
        &:has(+ tr[data-group-id="${'${attr(data-group-id)}'}"]:hover) td  
        {
          background-color: #f5f5f5 !important; // Même couleur que la couleur de fond normale
        }
      }

      // Style pour le bouton d'ajout
      .add-maintenance-recommendations {
        background-color: transparent;
        td {
          border: none;
          background-color: transparent;
        }
      }
      
      select {
        border: none;
        padding: 6px;
      }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    button {
      margin: 0 5px;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
