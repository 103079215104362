.devisCon {
    .devisTable2 {
        position: absolute;
        margin-top: 30px;
        width: 800px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        .ReactTable .rt-thead .rt-th{
             font-weight: bold;
        }
        .ReactTable{
            border: 2px solid black;
            .rt-thead.-header{
                pointer-events: none;
                .rt-resizer{
                    pointer-events: visible;
                }
            }
            .rt-head {
                .rt-tr {
                    font-weight: bold;
                }
            }

            .rt-table {
                background: white;
            }
    
            .rt-tbody {
                background-color: white;

                .titleDevisCon2 {
                    font-size: 15px;
                    font-weight: bold;  
                }

                .rt-tr-group{
                    .rt-tr{
                        .rt-td:first-child{
                            border-right: 1px solid black;
                        }
                        .rt-td:last-child{
                            text-align: center;
                        }
                    }
                }
                
                .rt-tr-group:first-child{
                    border-top: 1px solid black;
                    border-bottom: 1px solid black;
                }

                .rt-tr-group:nth-child(7){
                    border-top: 1px solid black;
                    border-bottom: 1px solid black;

                    .rt-td:first-child {
                        border-right: 1px solid black;
                    }
                
                }
                
                .rt-tr-group:nth-child(12){
                    border-top: 1px solid black;
                
                    .rt-td:first-child {
                        border-right: 1px solid black;
                    }  
                }

                .rt-tr-group{
                    border: none;
                }      
            }
        }


        .devisConSuite {
            margin-top: 3vh;
            .ReactTable .rt-tbody .rt-tr-group:nth-child(1){
                border: none;
                border-top: 1px solid;
            }

            .ReactTable .rt-tbody .rt-tr-group:last-child {
                border-top: 1px solid black;
            }
        }
            
        .pagination-bottom{
            display: none;
        }
    }
}

@media (max-width:600px){
    .ReactTable{
        width: 1000px;
        height: 500px;
        font-size: 10px;
        position: absolute;
        top:40%;
        left: 10px;
    }
}
