.vicValid {
  position: absolute;
  width: 100%;
  .vicmenu {
    position: relative;
    display: flex;
    top: 50px;
    margin-left: 5rem;
    .ndossier {
      top: 0;
    }
    .lot {
      margin-left: 5rem;
    }
    .entrepriselist {
      margin-left: 5rem;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .data {
    position: relative;
    top: 80px;
    margin: auto auto 110px 50px;
    button {
      margin-top: 20px;
      margin-right: 40px;
      background-color: rgba(255, 127, 80, 0.73);
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .numPages {
    font-size: 20px;
    text-align: right;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
  }
}
