.pre-diuo-validation {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    padding: 60px 0 30px 0;

    button {
      margin: 0 5px;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
