.container {
  display: flex;
  align-items: center;
  .new-msg {
    margin: 60px auto;
    display: flex;
    flex-direction: column;

    .text-field {
      width: 250px;
      margin: 3px 0;
      background-color: #ffb129;
      border-radius: 5px;
      &:nth-child(2) {
        margin-bottom: 40px;
      }
      &:nth-child(6) {
        width: 100%;
      }
    }
    .password-toggle-btn {
      width: 35px;
      height: 35px;
    }
    .msg-field {
      width: 575px;
      margin: 3px 0;
      background-color: #fda40b;
      border-radius: 5px;
      .MuiOutlinedInput-multiline {
        padding: 10px;
      }
    }

    .group-btn {
      display: flex;
      .spinner{
        margin-right: 10px;
      }
      button {
        color: white;
        background-color: #ff7f50;
        &:hover {
          background-color: #ff9b61;
        }
        &:nth-last-child(1) {
          margin-left: 7px;
        }
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}
