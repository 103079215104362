.rj-obs {
  position: absolute;
  width: 100%;
  .rjmenu {
    position: relative;
    display: flex;
    top: 50px;
    margin-left: 5rem;
    .ndossier {
      top: 0;
    }
    .numero {
      margin-left: 5rem;
    }
    .advancement {
      margin-left: 5rem;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .data {
    position: relative;
    top: 80px;
    margin-left: 50px;
    .button-group {
      button {
        color: white;
      }
      button:hover {
        background-color: rgb(253, 181, 142);
      }
    }
    .obsTable {
      .delete {
        margin: 0;
      }
      .BaseTable__table-main {
        outline: none;
      }
      .BaseTable__header-cell {
        border: 1px solid black;
        background-color: rgb(251, 228, 213);
      }
      .BaseTable__row-cell {
        border: 1px solid black;
        font-size: 15px;
      }
      .BaseTable:not(.BaseTable--dynamic) .BaseTable__row-cell-text,
      .BaseTable .BaseTable__row--frozen .BaseTable__row-cell-text {
        white-space: normal;
      }
    }
    .photoTable {
      margin-top: 30px;
      .add-del-button {
        margin: 0;
      }
      .BaseTable__table {
        position: relative;
      }
      .BaseTable__table-main {
        outline: none;
      }
      .BaseTable__header-cell {
        border: 1px solid black;
        background-color: rgb(217, 217, 217);
      }
      .BaseTable__row-cell {
        border: 1px solid black;
        font-size: 15px;
      }
      .BaseTable:not(.BaseTable--dynamic) .BaseTable__row-cell-text,
      .BaseTable .BaseTable__row--frozen .BaseTable__row-cell-text {
        white-space: normal;
      }
    }
  }
  .img-observation {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;
    .img-item {
      position: relative;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-delete {
        color: "white";
        width: 32px;
        height: 32px;
        background-color: rgba(190, 187, 187, 0.541);
      }
      .item-delete:hover {
        color: "white";
        width: 32px;
        height: 32px;
        background-color: rgb(253, 181, 142);
      }
      p {
        margin-top: 10px;
      }
    }
  }
  .button-group {
    button {
      margin: 20px 10px 20px 0;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .spinner {
    width: 20px;
    margin-top: 17px;
    position: absolute;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
.popover-levels {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px black;
  button {
    position: relative;
    font-size: 10px;
    color: black;
    margin-top: 2px;
    width: 50px;
  }
}
