.social-network {
  position: absolute;
  width: 20%;
  font-size: 30px;
  margin-top: 3px;
  
    @media screen and (max-width: 650px){
      width: 30%;
    }
  
    ul {
      display: flex;
      width: 100%;
      min-width: 100px;
      justify-content: space-around;
      list-style-type: none;

      .facebookIcon > li{
        //color: rgb(76,110,245);
        color: white;
      }

      .twitterIcon > li{
        //color: rgb(29,161,242);
        color: white;
      }

      .coordogecIcon > li{
        color: white;
      }

      @media screen and (max-width: 450px){
        min-width: 100%;
      }
  
      a {
        padding: 1rem;
        transition: 1.1s ease-out;
      }
    }
  }
  @media only screen and (max-width:400px) and (max-height: 800px){
    ul > a > li{
       font-size: 25px;
    }
}