.reminder-fac-amendment {
  .ndossier {
    position: absolute;
    display: flex;
    top: 190px;
    margin-left: 5rem;
    label {
      margin: 0 5px;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  .switch-reminder {
    margin: 35px 0 -25px 0;
    text-align: center;
    span {
      font-size: 18px;
    }
    .no-active {
      color: transparent;
    }
    .active1 {
      color: #fff;
      background-color: #ff7800;
      padding: 9px;
      border: 2px solid white;
      border-radius: 15px;
    }
    .active2 {
      color: #fff;
      background-color: red;
      padding: 9px;
      border: 2px solid white;
      border-radius: 15px;
    }
    .MuiSwitch-colorPrimary.Mui-checked {
      color: #fff;
    }
  }
}
