.htp {
  position: absolute;
  width: 100%;
  h1 {
    margin-top: 120px;
    text-align: center;
    text-decoration: underline;
  }
  button {
    background-color: #ff7f50;
  }
  .ndossier {
    margin-top: 70px;
    margin-left: 5rem;
  }
  
  .htp-table {
    margin: 0 0 30px 50px;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1150px) {
    .htp-table {
      flex-direction: column;
      align-items: center;
    }
  }
  .htp-subtable {
    width: "500px";
    margin-right: 50px;
    text-align: center;
    .MuiInput-input {
      text-align: center;
    }
    .subtable-btn {
      display: flex;
      justify-content: flex-end;
      .btn-valid {
        color: white;
        background-color: #ff7f50;
        margin: 20px 10px 0 auto;
      }
      .btn-reset {
        color: white;
        background-color: #f5b039;
        margin: 20px 0 0 0;
      }
      .btn-valid:hover {
        background-color: #ff9b61;
      }
      .btn-reset:hover {
        background-color: #ffbf50;
      }
    }
  }
  .head-row {
    .MuiTableCell-root:first-child.MuiTableCell-head {
      width: 400px;
      text-align: center;
      font-weight: bold;
      color: #fff;
      background-color: #777777;
      border-right: 1px solid #fff;
    }
    .MuiTableCell-root:nth-child(2).MuiTableCell-head {
      width: 100px;
      text-align: center;
      font-weight: bold;
      color: #fff;
      background-color: #777777;
    }
  }
  .subtitle {
    background-color: #a1a1a1;
    text-align: center;
    // color: #fff;
  }
}
