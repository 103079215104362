.calcDevis{
    position: absolute;
    width: 100%;
    .ndossier{
        margin-left: 50px;
    }
    h1{
        text-align: center;
        margin-top: 120px;
        text-decoration: underline;
    }
    .infosDevis{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 5vh;
        font-size: 20px;
        div{
            display: flex;
            flex-direction: column;
            width: fit-content;
            margin-left: 50px;
            margin-bottom: 10px;
            div{
                display: block;
            }
        }

        input:focus {

            outline: none;
        }

        width: "500px";
        margin-right: 50px;
        text-align: center;
        .MuiInput-input {
          text-align: center;
        }
        .subtable-btn {
          display: flex;
          justify-content: flex-end;
          .btn-valid {
            color: white;
            background-color: #ff7f50;
            margin: 20px 10px 0 auto;
          }
          .btn-reset {
            color: white;
            background-color: #f5b039;
            margin: 20px 0 0 0;
          }
          .btn-valid:hover {
            background-color: #ff9b61;
          }
          .btn-reset:hover {
            background-color: #ffbf50;
          }
        }
        .subtitle {
            background-color: #a1a1a1;
            text-align: center;
            font-size: large;
        }
        .items-select{
            width: 150px;
            text-align: center;
            font-size: 15px;
            margin-right: -7px;
        }

    }


    .infosCalc{
        font-size: 20px;
        margin-top: 5vh;
        margin-left: 50px;
        div{
            margin-bottom: 10px;
        }

        input:disabled {
            background-color: white;
            border: 1px grey solid;
            color: black;
        }
        
    }

    .calculButton{
        order: 1;
        flex: 0 0 20%;
        margin: 20px auto;
        margin-left: 20px;
        background-color: orange;
        color: white;
    }
    .gen-btn{
        margin-left: 70px;
    }

    .calculButton:hover {
        background-color: rgb(255, 183, 49);
    }
    .genPDF{
        position: relative;
        top: 0px;
        float: none;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        .closeIcon{
          position: absolute;
          z-index: 10;
          right: 10px;
          top: 65px;
        }

        canvas {
            clip-path: inset(50px 1.15vw 40vh 2.2vw);
        }
    }

    .loadingPdf {
        svg { 
            position: absolute;
            top: -40px;
            left : 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            height: 200px;
        } 
    }

    .calculButton:last-child {

        display: none;
    }

}  


@media (max-width:600px){
    
        .calcDevis{
            .boutgener{
                position: relative;
                top: 800px;
                left: 0px;
            }
                .d{
                    position: absolute;
                    top: 5px;
                    left:-30px;
                }
        
                .infosCalc{
                    position: absolute;
                    top: 690px;
                    left : -30px;
                    font-size: 18px;

                }
                .complex{
                    position: absolute;
                    top: 420px;
                    left :  10px;
                    font-size: 18px;

                }
                .catcop{
                    position: absolute;
                    top: 210px;
                    left : 7px;
                    font-size: 18px;
                }
                .infosDevis{
                    position: relative;
                    top: 60px;
                    left : -40px;  
                    font-size: 18px;
                }
                .infosop{
                    position: relative;
                    top: 20px;
                    left : 5px;  
                    font-size: 18px;
                
                }
        .genPDF {
            display: none;
        }

        .calculButton:last-child {
            display: block;
        }
        .infosDevis {
            div {
                margin-left: 7vw;
            }
        }
        .calculButton {
            position: relative;
            top: 780px;
            left: 50px;
            font-size: 15px;
            margin:10px ;
            .calcDevis{
    position: absolute;
    width: 100%;
    .ndossier{
        margin-top: 80px;
        margin-left: 50px;
    }
    .d{
        position: relative;
        top: -50px;
        left:-10px;
    }
    .infosDevis{
        position: absolute;
        top: 30px;
        left:-60px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10vh;
        font-size: 20px;
        div{
            display: flex;
            flex-direction: column;
            width: fit-content;
            margin-left: 50px;
            margin-bottom: 10px;
            div{
                display: block;
            }
        }

        input:focus {
            outline: none;
        }
    }

    .infosCalc{
        position: absolute;
        top: 330px;
        left:-8px;
        font-size: 20px;
        margin-top: 5vh;
        margin-left: 50px;
        div{
            margin-bottom: 10px;
        }

        input:disabled {
            background-color: white;
            border: 1px grey solid;
            color: black;
        }
        
    }
    .catcop{
        position: relative;
        top: 0px;
        left:60px; 
    }
    .complex{
        position: relative;
        top: 0px;
        left: 70px;
    }
    .calculButton{
        position: relative;
        top: 650px;
        left:60px;
        order: 1;
        flex: 0 0 20%;
        margin-top: 10vh;
        margin-left: 50px;
        background-color: orange;
        color: white;
    }

    .calculButton:hover {
        background-color: rgb(255, 183, 49);
    }
    .genPDF{
        position: relative;
        top: 0px;
        float: none;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        .closeIcon{
          position: absolute;
          z-index: 10;
          right: 10px;
          top: 65px;
        }

        canvas {
            clip-path: inset(50px 1.15vw 40vh 2.2vw);
        }
    }

    .loadingPdf {
        svg { 
            position: absolute;
            top: -40px;
            left : 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            height: 200px;
        } 
    }

    .calculButton:last-child {

        display: none;
    }

}   


@media (max-width:600px){
    
        .calcDevis{
            line-height: 50px ;
            .boutgener{
                position: relative;
                top: 800px;
                left: 0px;
            }
                .d{
                    position: absolute;
                    top: 5px;
                    left:-30px;
                }
        
                .infosCalc{
                    position: absolute;
                    top: 690px;
                    left : -30px;
                    font-size: 18px;

                }
                .complex{
                    position: absolute;
                    top: 420px;
                    left :  10px;
                    font-size: 18px;

                }
                .catcop{
                    position: absolute;
                    top: 210px;
                    left : 20px;
                    font-size: 18px;
                }
                .infosDevis{
                    position: relative;
                    top: 60px;
                    left : -40px;  
                    font-size: 18px;
                }
                .infosop{
                    position: relative;
                    top: 20px;
                    left : 5px;  
                    font-size: 18px;
                
                }
        .genPDF {
            display: none;
        }

        .calculButton:last-child {
            display: block;
        }
        .infosDevis {
            div {
                margin-left: 7vw;
            }
        }
        .calculButton {
            position: relative;
            top: 780px;
            left: 50px;
            font-size: 15px;
            margin:10px ;
            display: flex;
        
        }
        .calculButton:last-child {
           margin-bottom: 2vh;
        }
    }
    
}

@media (min-width:801px) and (max-width: 1080px){
    
    .calcDevis{
        .d{
            position: absolute;
            top: -70px;
            left:-10px;
        }
        .calculButton{
            position: relative;
            top: 800px;
            left: 0px;
        }
    
        .infosCalc{
            position: absolute;
            top: 610px;
            left : -10px;
        }
        .complex{
            position: absolute;
            top: 350px;
            left : 0px;
        }
        .catcop{
            position: absolute;
            top: 170px;
            left : 0px;
        }
        .infosDevis{
            position: absolute;
            top: 60px;
            left : -60px;  
        }
        .genPDF {
            top: 100vh;
            margin :0;
            float: none;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }

        .loadingPdf {    
            svg { 
                position: absolute;
                // margin-top: 5vh;
                left : 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                height: 150px;           
            } 
        }
    }
}




        
        }
        .calculButton:last-child {
           margin-bottom: 2vh;
        }
    }
    
}

@media (min-width:801px) and (max-width: 1080px){
    
    .calcDevis{
        .d{
            position: absolute;
            top: -70px;
            left:-10px;
        }
        .calculButton{
            position: relative;
            top: 800px;
            left: 0px;
        }
    
        .infosCalc{
            position: absolute;
            top: 610px;
            left : -10px;
        }
        .complex{
            position: absolute;
            top: 350px;
            left : 0px;
        }
        .catcop{
            position: absolute;
            top: 170px;
            left : 0px;
        }
        .infosDevis{
            position: absolute;
            top: 60px;
            left : -60px;  
        }
        .genPDF {
            top: 100vh;
            margin :0;
            float: none;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }

        .loadingPdf {
        
            svg { 
                position: absolute;
                margin-top: 15vh;
                left : 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                height: 150px;           
            } 
        }
    }
}


