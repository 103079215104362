.data-vicvalidations {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  .buttons-container {
    display: flex;
    justify-content: center;
    margin: 10px auto 30px;

    button {
      margin: 0 5px;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
