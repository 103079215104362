.diuo-page4 {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .custom-table {
      width: 851px;
      border-collapse: collapse;
      margin-top: 25px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;
        
        &:hover{
          background-color: #eee;
        }
        
        td {
          padding: 15px;
          input[type="text"] {
            width: 98%;
            border: none;
            padding: 6px;
            border-radius: 5px;
          }
        }
        
        input[type="date"] {
          padding: 8px;
          border: none;
          border-radius: 5px;
        }

        // textarea {
        //   width: 98%;
        //   height: 100px;
        //   border: none;
        //   padding: 6px;
        // }
      }

      .custom-cell-title {
        border: 1px solid #01308d;
        text-align: center;
        color: #fff;
        background-color: #01308d;
        .custom-cell-label {
          padding: 20px;
        }
      }
    }
    
    .img-item {
      position: relative;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .item-delete {
        color: "white";
        width: 32px;
        height: 32px;
        background-color: rgba(190, 187, 187, 0.541);
      }

      .item-delete:hover {
        color: "white";
        width: 32px;
        height: 32px;
        background-color: rgb(253, 181, 142);
      }

      p {
        margin: 10px 0 -8px;
      }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    button {
      margin: 0 5px;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
