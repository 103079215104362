.graphChantier {
    position: absolute;
    top: 533px;
    width: 66.664%;
    height: 435px;
    text-align: center;
    font-family: Calibri;
    border: solid 1px black;
    border-top: none;
    font-size: 14px;

    .titregraph{
        display: block;
        position: relative;
        background-color: #ff7f50;
        color: white;
        border-bottom: solid 1px black;
        width: auto;
        bottom:0;
        top:0;
        left:0;
        right:0;
        padding: 10px;
    }

    span{
        position: relative;
        font-size: 50px;
        top: 0px;
    }
}
@media(max-width:1400px){
    .graphChantier{
        width: auto;
        left:0;
        right:0;
        div{
            width: 99%;
        }
    }
}
@media(max-width:900px){
    .graphChantier{
        div{
            margin-top: 7.5%;
        }
    }
}
@media(max-width:700px){
    .graphChantier{
        top: 1015px;
        div{
            margin-top: 15%;
        }
    }
}