.data-page8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 65px;

  .specific-tasks {
    margin-left: 20px;
    h3 {
      text-decoration: underline;
    }
    .specific-task {
      margin-bottom: 10px;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin: 10px auto 30px;

    button {
      margin: 0 5px;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .signature-container {
    margin-top: 20px;
    .signature {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .signature-items {
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 10px 0;

        canvas {
          width: 200px;
          height: 100px;
          border: 1px solid black;
          background-color: #fff;
          box-shadow: 0 0 10px rgb(122, 122, 122);
        }

        label {
          margin-right: 20px;
        }

        button {
          margin: 0 20px 0 10px;
          background-color: green;

          &:hover {
            background-color: rgb(28, 172, 28);
          }
        }
      }
    }
  }
  .spinner {
    margin-top: 0;
    position: inherit;
  }
}
