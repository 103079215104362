.table-fac-amendment {
  .ndossier {
    position: absolute;
    display: flex;
    top: 190px;
    margin-left: 5rem;
    label {
      margin-right: 5px;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .table-list {
    width: calc(100% - 40px);
    margin: 50px auto 0;
    .btn-group {
      display: flex;
      justify-content: flex-end;
      button {
        margin: 10px 0 30px 10px;
        color: white;
        background-color: #ff7f50;
        &:hover {
          background-color: #ff9b61;
        }
      }
    }
    .centered-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .rt-th {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .total-table {
      width: 100%;
      margin: 15px 0 5px 0;
      display: flex;
      justify-content: flex-end;
      .table-container {
        width: 300px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        table {
          width: 100%;
          border-collapse: collapse;
          th {
            text-align: left;
            padding: 10px;
            background-color: #eaeaea;
            border-bottom: 1px solid #ccc;
          }

          td {
            text-align: right;
            padding: 10px;
            background-color: #f4f4f4;
            border-bottom: 1px solid #ccc;
          }

          tr:nth-child(odd) {
            background-color: #f9f9f9;
          }
        }
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .spinner {
    width: 20px;
    margin-top: 10px;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
}
