.modifValid{
    display: flex;
    flex-direction: column;
    .title{
        position: relative;
        margin-top: 15vh;
        text-align: center;
    }
    .verifAdmin{
        position: relative;
        width: 20em;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        margin-top: 80px;
        div{
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
}
 