.devisRea {
  .devisTable2 {
    position: absolute;
    margin-top: 30px;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    .ReactTable .rt-thead .rt-th {
      font-weight: bold;
    }
    .ReactTable {
      border: 2px solid black;
      .rt-thead.-header {
        pointer-events: none;
        .rt-resizer {
          pointer-events: visible;
        }
      }
      .rt-head {
        .rt-tr {
          font-weight: bold;
        }
      }

      .rt-table {
        background: white;
      }

      .rt-tbody {
        background-color: white;

        .titleDevisCon2 {
          font-size: 15px;
          font-weight: bold;
        }

        .rt-tr-group:first-child {
          border-top: 1px solid black;
          border-bottom: 1px solid black;

          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group {
          .rt-tr {
            .rt-td:first-child {
              border-right: 1px solid black;
            }
            .rt-td:last-child {
              text-align: center;
            }
          }
        }

        .rt-tr-group:nth-child(2) {
          cd .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(3) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(4) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(5) {
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(6) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(7) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(8) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(9) {
          border-top: 1px solid black;
          border-bottom: 1px solid black;

          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(9) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(10) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(11) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(12) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(13) {
          border-top: 1px solid black;

          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group {
          border: none;
        }
      }
    }

    .devisConSuite {
      margin-top: 3vh;

      // .ReactTable .rt-thead.-header {
      // //    border-top: 1px solid black;
      // }
      .ReactTable .rt-tr {
        border: none;
      }

      .ReactTable .rt-tbody .rt-tr-group:nth-child(1) {
        border: none;
        border-top: 1px solid;
      }
      .ReactTable .rt-tbody .rt-tr-group:nth-child(2) {
        .rt-td:first-child {
          border-right: 1px solid black;
        }
      }

      .ReactTable .rt-tbody .rt-tr-group:last-child {
        border-top: 1px solid black;
      }
    }

    .pagination-bottom {
      display: none;
    }
  }
}
// @media (min-width: 801px) and (max-width: 1080px) {
//   .d {
//     position: relative;
//     top: 50px;
//     left: 0px;
//   }
//   .ndossier {
//     position: relative;
//     top: 100px;
//     left: -10px;
//   }
//   .devisRea {
//     .ReactTable {
//       min-height: 500px;
//       margin-top: 60px;
//     }
//   }
// }
