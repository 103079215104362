.global-fac-amendment {
  .docmenu {
    position: fixed;
    top: 50px;
    right: 0;
    z-index: 100;
  }
  .ndossier {
    position: absolute;
    display: flex;
    top: 190px;
    margin-left: 5rem;
    label {
      margin-right: 5px;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .global-table {
    width: calc(1860px - 40px);
    margin: 20px auto 0 auto;
    .rt-tbody {
      .rt-tr-group {
        height: auto;
      }
    }
    .btn-group {
      display: flex;
      justify-content: flex-end;
      button {
        margin: 10px 0 30px 10px;
        color: white;
        background-color: #ff7f50;
        &:hover {
          background-color: #ff9b61;
        }
      }
      .btn-filter {
        background-color: green;
        &:hover {
          background-color: rgb(37, 167, 37);
        }
        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }
    }
    .rt-th {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .centered-cell {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
    }
    .first-div {
      margin-left: 5px;
      justify-content: left;
    }
    .total-table {
      width: 100%;
      margin: 15px 0 5px 0;
      display: flex;
      justify-content: flex-end;
      .table-container {
        width: 325px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        table {
          width: 100%;
          border-collapse: collapse;
          th {
            text-align: left;
            padding: 10px;
            background-color: #eaeaea;
            border-bottom: 1px solid #ccc;
            width: 203px;
          }

          td {
            text-align: right;
            padding: 10px;
            background-color: #f4f4f4;
            border-bottom: 1px solid #ccc;
          }

          tr:nth-child(odd) {
            background-color: #f9f9f9;
          }
        }
        .table-bank {
          margin-bottom: 20px;
        }
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
    .spinner {
      width: 20px;
      margin-top: 10px;
    }

    .filter-container {
      width: 400px;
      margin: 0 auto 15px auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      align-items: center;

      .filter-row {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .date-filter {
          width: 50%;
        }
      }

      .filter-row:first-child {
        justify-content: space-around;
        margin-bottom: 13px;
      }

      h4 {
        margin: 0 0 10px 0;
      }

      label {
        display: block;
        margin-bottom: 5px;
      }

      input[type="date"] {
        margin-bottom: 10px;
        padding: 3px;
        width: calc(100% - 10px);
        border: 1px solid #ccc;
        border-radius: 3px;
        text-align: center;
        font-size: 14px;
      }

      input[type="checkbox"] {
        margin: 0 7px 6px 0;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 3px;
      }

      button:first-of-type {
        color: white;
        background-color: #ff7f50;
        &:hover {
          background-color: #ff9b61;
        }
        margin-right: 10px;
      }

      button:last-of-type {
        color: white;
        background-color: #f5b039;
        &:hover {
          background-color: #f9d18c;
        }
      }
    }
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
}
