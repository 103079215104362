.vicEmploymentLaw {
  position: absolute;
  width: 100%;
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  h2 {
    text-align: center;
  }
  .ct-categories {
    text-align: center;
  }
  .cat-button {
    margin: 0 10px 10px 0;
    background-color: #ff7f50;
    &:hover {
      background-color: #ff9b61;
    }
  }
  .ct-button {
    margin-top: 20px;
    margin-left: 20px;
    background-color: #ff7f50;
    &:hover {
      background-color: #ff9b61;
    }
  }
  .buttons {
    text-align: end;
  }
  .btn-button {
    margin-top: 20px;
    margin-left: 20px;
    background-color: #ff7f50;
    &:hover {
      background-color: #ff9b61;
    }
  }
  .display-employment-law {
    margin: 30px;
    @media screen and (min-width: 1100px) {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: calc((100% - 1100px) / 2);
      margin-right: calc((100% - 1100px) / 2);
    }
    .BaseTable__header-cell {
      border: 1px solid black;
      background-color: #ccc;
    }
    .BaseTable__row-cell {
      border: 1px solid black;
      font-size: 15px;
    }
    .BaseTable__row {
      .BaseTable__row-cell:nth-child(4) {
        display: flex;
        justify-content: flex-start;
        padding-left: 40px;
      }
    }
    .read-employment-law {
      margin: 20px 40px 20px 48px;
    }
    .edit-employment-law {
      margin: 20px 40px 20px 48px;
    }
    .del-employment-law {
      margin: 20px 40px 20px 48px;
    }
  }
  // .lot-form{
  // }

  .display-categories {
    margin: 30px;
    @media screen and (min-width: 630px) {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: calc((100% - 630px) / 2);
      margin-right: calc((100% - 630px) / 2);
    }
    .BaseTable__header-cell {
      border: 1px solid black;
      background-color: #ccc;
    }
    .BaseTable__row-cell {
      border: 1px solid black;
      font-size: 15px;
    }
    .BaseTable__row {
      .BaseTable__row-cell:nth-child(4) {
        display: flex;
        justify-content: flex-start;
        padding-left: 40px;
      }
    }
    .read-employment-law {
      margin: 20px 40px 20px 48px;
    }
    .edit-employment-law {
      margin: 20px 40px 20px 48px;
    }
    .del-employment-law {
      margin: 20px 0px 20px -23px;
    }
  }

  .questions-by-lot {
    display: flex;
    justify-content: space-between;
    .MuiSvgIcon-root {
      background-color: transparent;
      font-size: 16px;
      margin-top: -3px;
    }
  }

  .spinner {
    margin-left: calc((100% - 40px) / 2);
  }
  .display-questions-table {
    position: relative;
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }

    .overlay-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .display-questions-lot {
      margin-left: -5px;
      width: 500px;
      position: relative;
      z-index: 1000;
      .MuiGrid-container {
        display: block;
      }
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
