.facture-recap {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .ndossier {
    position: inherit;
    margin: 0;
    margin-left: 5rem;
  }
  .table-list {
    width: calc(1100px - 40px);
    margin: 20px auto 0 auto;
    .rt-tbody {
      .rt-tr-group {
        height: 50px;
      }
    }
    .btn-group {
      display: flex;
      justify-content: flex-end;
      button {
        margin: 10px 0 30px 10px;
        color: white;
        background-color: #ff7f50;
        &:hover {
          background-color: #ff9b61;
        }
      }
    }
    .rt-th {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .centered-cell {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
    }
    .first-div {
      margin-left: 5px;
      justify-content: left;
    }
    .spinner {
      width: 20px;
      margin-top: 10px;
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .recap-table {
    width: 100%;
    margin: 15px 0 5px 0;
    display: flex;
    justify-content: flex-end;
    .table-container{
      width: 427px;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      table {
        width: 100%;
        border-collapse: collapse;
        th {
          text-align: left;
          padding: 10px;
          background-color: #eaeaea;
          border-bottom: 1px solid #ccc;
        }

        td {
          text-align: right;
          padding: 10px;
          background-color: #f4f4f4;
          border-bottom: 1px solid #ccc;
        }
        
        tr:nth-child(odd) {
          background-color: #f9f9f9;
        }
      }
      .table-ref{
        margin-top: 20px;
        td{
          text-align: center;
          input{
            width: 60%;
            margin: 0 auto;
            padding: 3px;
            border: 1px solid #ccc;
            border-radius: 5px;
            background-color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
}
