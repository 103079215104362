// CreaDossier.scss
.creaDossier {
  font-family: "Arial", sans-serif;
  color: #333;

  .title {
    position: absolute;
    top: 7vh;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    font-weight: bold;
    color: black;
  }

  .cadreDoss {
    position: relative;
    background: white;
    border-radius: 12px;
    box-shadow: 0 0 10px rgb(122, 122, 122);
    padding: 1.5rem;
    max-width: 800px;
    margin: 17vh auto 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .formDoss {
      display: flex;
      gap: 2rem;

      .opInfos,
      .mouInfos {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        div {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;

          label {
            font-size: 1rem;
            color: #555;
          }

          input,
          select {
            padding: 0.5rem;
            border: 1px solid #ddd;
            border-radius: 6px;
            font-size: 1rem;
            transition: border-color 0.3s ease;

            &:focus {
              border-color: #ff7f50;
              outline: none;
            }
          }
        }

        .durée {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-bottom: 5px;

          .MuiSlider-root {
            color: #ff7f50;
          }
          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            padding-left: 5px;
          }
          input {
            width: 50px;
            text-align: center;
          }
        }
        .MuiCheckbox-colorSecondary {
          margin-left: -13px;
          color: #ff7f50;
          background-color: transparent;

          &:hover {
            border-color: grey;
          }
        }
      }
    }

    button {
      color: white;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 6px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      background-color: green;
      &:hover {
        background-color: rgb(37, 167, 37);
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  }

  .listeDoss {
    margin: 1rem auto;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    padding-bottom: 30px;

    div {
      background: white;
      border-radius: 8px;
      box-shadow: 0 0 10px rgb(122, 122, 122);
      padding: 0.5rem;
      width: 230px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        font-size: 1rem;
        color: #555;
      }

      button {
        background-color: #ff7f50;
        color: #fff;

        &:hover {
          background-color: #ff9b61;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .creaDossier {
    .title {
      font-size: 1.5rem;
    }

    .cadreDoss {
      padding: 1rem;
      margin: 10vh auto;

      .formDoss {
        flex-direction: column;
      }
    }

    .listeDoss {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }
}
