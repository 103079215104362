.risqueTable {
  .risqueTableFloor {
    display: flex;
    // align-items: flex-start;
    .listFacteurs {
      width: 100%;
    }
  }
  .BaseTable {
    .BaseTable__header-cell:first-child {
      background-color: rgb(255, 192, 0);
    }
    .BaseTable__header-cell:nth-child(2) {
      background-color: white;
    }
    .BaseTable__header-cell:nth-child(3) {
      background-color: #e8e8e8;
    }
    .BaseTable__header-cell:nth-child(4) {
      background-color: #d5d5d5;
    }
    .BaseTable__header-cell:nth-child(5) {
      background-color: #bdbdbd;
    }
    .BaseTable__header-cell:nth-child(6) {
      background-color: #9e9e9e;
    }
    .BaseTable__header-cell:nth-child(7) {
      background-color: #757575;
    }
    .BaseTable__header-cell:nth-child(8) {
      background-color: #616161;
    }
    .BaseTable__header-cell:nth-child(9) {
      background-color: #424242;
    }
    .BaseTable__header-cell:nth-last-child(1) {
      background-color: white;
    }
    .BaseTable__header-cell {
      border: 1px black solid;
    }
    .BaseTable__row-cell {
      border: 1px black solid;
    }
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
  }
}
.popover-levels {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px black;
  button {
    position: relative;
    font-size: 10px;
    color: black;
    margin-top: 2px;
    width: 50px;
  }
}
