.handleNotifs{
    .notif{
        background-color: rgba(255, 127, 80, 0.8);
        border: 1px solid black;
        color: white;
        font-size: 16px;
        position: relative;
        text-align: center;
        border-radius: 10px;
        top: 100px;
        width: 80%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        .notifTitle{
            text-decoration: underline;
            font-size: 20px;
            svg{
                margin-top: 10px;
                font-size: 30px;
                margin-right: 20px;
            }
        }
        .demandeButton{
            position: absolute;
            background-color: rgb(235, 105, 57);
            border-radius: 10px;
            font-size: 12px;
            color: white;
            top: 20px;
            right: 20px;
        }
        .sendBy{
            font-size: 12px;
        }
    }
}