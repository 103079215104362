.schedule {
  padding-bottom: 20px;
  .ndossier {
    position: absolute;
    display: flex;
    top: 200px;
    margin-left: 5rem;
    select {
      margin: 0 5px;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 75px 20px 0 20px;
    .schedule-table {
      box-shadow: 0 0 10px rgb(122, 122, 122);
      .custom-cell-thead {
        text-align: center;
        background-color: #ff7f50;
        color: white;
        .custom-cell-label {
          padding: 10px;
        }
      }

      .custom-cell-thead:nth-of-type(2) {
        color: #fff;
        background-color: #757575;
        strong:nth-of-type(1) {
          color: #fff;
          font-size: 20px;
        }
      }

      .custom-cell-thead:nth-of-type(3) {
        color: #000;
        background-color: #ccc;
        strong:nth-of-type(1) {
          color: red;
          margin-right: 20px;
        }
      }

      .custom-cell-item {
        text-align: center;
        .custom-cell-label {
          background-color: #fff;
          padding: 5px;
          width: 120px;
          &:nth-of-type(13) {
            background-color: transparent;
          }
        }
      }
    }
  }

  .group-btn {
    width: 100%;
    margin-bottom: 20px;
    button {
      color: white;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
