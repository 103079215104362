.data-page5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 65px;

  .specific-tasks {
    .organism {
      border-radius: 10px;
      padding-left: 5px;
    }
  }

  .custom-table {
    width: 810px;
    border-collapse: collapse;
    margin-top: 15px;
    box-shadow: 0 0 10px rgb(122, 122, 122);

    .custom-cell {
      padding: 15px;
      border: 1px solid #ddd;
      text-align: center;

      .custom-cell-check {
        border: 1px solid #ddd;
        padding: 15px;
      }

      .custom-cell-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
      }
    }
    tr {
      background-color: #f5f5f5;
    }

    td,
    th {
      position: relative;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    margin: 10px auto 30px;

    button {
      margin: 0 5px;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .signature-container {
    .signature {
      width: 800px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .signature-items {
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 10px 0;

        canvas {
          width: 200px;
          height: 100px;
          border: 1px solid black;
          background-color: #fff;
          box-shadow: 0 0 10px rgb(122, 122, 122);
        }

        label {
          margin-right: 29px;
        }

        button {
          margin-left: 10px;
          background-color: green;

          &:hover {
            background-color: rgb(28, 172, 28);
          }
        }
      }
      .tampon {
        img {
          height: 100px;
          border: 1px solid black;
          box-shadow: 0 0 10px rgb(122, 122, 122);
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
          color: red;
          background-color: transparent;
          border: none;
          font-size: 1.5rem;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  .spinner {
    margin-top: 0;
    position: inherit;
  }
}
