@keyframes fade{
    0% {opacity: 1;}
    50%   {opacity:0; }
  100% { opacity:1; }
}
@keyframes fadein{
    0% {opacity: 0;}
  100% { opacity:1; }
}
.contact{
    position: absolute;
    animation-duration: 5s;
    animation-name: fade;
    animation-iteration-count: infinite;
    transition: none;
    margin-top: 40px;
    right: 13vw;
}
.contact:hover{
    animation-duration: 2s;
    animation-name: fadein;
    animation-iteration-count: 1;
    transition:none;
    cursor: pointer;
}
.logocontact{
    border-radius: 10%;
    height: 130px;
    width: 230px;
}
.flechebas2{
    position: inherit;
    height: 45px;
    margin-top: 150px;
    left: 100px;
}
.contact label{
    position: inherit;
    font-size: 20px;
    left: 91px;
    margin-top: 210px;
    font-family: Calibri;
}
@media (max-width:1000px){
    .contact{
        margin-top: 70px;
        right: 9vw;
    }
    .logocontact{
        width: 150px;
    }
    .flechebas2{
        width: 30px;
        left: 65px;
    }
    .contact > label{
        left: 55px;
        text-align: center;
        font-size: 15px;
    }
}
@media (max-width:700px){
    .contact{
        margin-top: 150px;
        right: 6vw;
    }
    .logocontact{
        width: 120px;
        height: 100px;
    }
    .flechebas2{
        display: none;
    }
    .contact > label {
        font-size: 12px;
        margin-top: auto;
        top: 110px;
        left: 45px;
    }
}
@media (max-width:500px){
    .contact{
        margin-top: 30px;
        right: 20px;
    } 
}
