@keyframes fade{
    0% {opacity: 1;}
    50%   {opacity:0; }
  100% { opacity:1; }
}
@keyframes fadein{
    0% {opacity: 0;}
  100% { opacity:1; }
}
.chantier {
    position: absolute;
	animation-duration: 5s;
	animation-name: fade;
    animation-iteration-count: infinite;
    margin-top: 40px;
    left: 14vw;
}
.chantier:hover{
    animation-duration: 2s;
    animation-name: fadein;
    animation-iteration-count: 1;
    transition:none;
    cursor: pointer;
}
.logochantier {
	border-radius: 10%;
	height: 130px;
	width: 210px;
	/* margin: 0 auto; */
}
.flechebas1 {
	height: 45px;
	margin-top: 150px;
	left: 80px;
	position: inherit;
}
.chantier label {
	font-size: 20px;
	margin-top: 210px;
	position: inherit;
    left: 45px;
    font-family: Calibri;
}
@media (max-width:1000px){
    .chantier{
        margin-top: 70px;
        left: 10vw;
    }
    .logochantier{
        width: 150px;
    }
    .flechebas1{
        width: 30px;
        left: 60px;
        top: 0px;
    }
    .chantier > label{
        text-align: center;
        font-size: 15px;
        left: 32px;
    }
}
@media (max-width:700px){
    .chantier{
        margin-top: 150px;
        left: 6vw;
    }
    .logochantier{
        width: 120px;
        height: 100px;
    }
    .flechebas1{
        display: none;
    }
    .chantier > label {
        font-size: 12px;
        margin-top: auto;
        top: 110px;
        left: 25px;
    }
}
@media (max-width:500px){
    .chantier{
        margin-top: 30px;
        left: 20px;
    } 
}
